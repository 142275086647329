
@import url(https://fonts.googleapis.com/css?family=Khula:700);
code {
  font-family: Khula;
}

@import url("https://fonts.googleapis.com/css?family=Courgette:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair:opsz,wght@5..1200,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Playfair:opsz,wght@5..1200,300&display=swap
");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #282434;
  font-family:Khula;
}

.sectionone {

  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
  font-size: 3rem;
  color: #eee;
  z-index: -1;
  text-align: center;
  background-image: url("images/street3.png");

  /* Set a specific height */
  min-height: 500px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: bottom ;
  background-repeat: no-repeat;
  background-size: cover;

  animation: fadeIn 1.5s ease-in-out;
}

.lussasign {
  position: relative;
  top:50px; bottom: 0px; left: 10%;
  height: 11vh;

}

.sectiontwo {
  display: flex;
  align-items: left;
  justify-content: left;
  z-index: 1;
  height: 100vh;
  font-size: 1.7rem;
  background: #111;
  text: rgb(218, 69, 255);
  

}
.console-container {
  position:relative;
  font-family:Khula;
  text-align:left;
  display:block;
  padding-right: 15%;
  color:rgb(223, 93, 255);
  top:0;
  bottom:0;
  left:2rem;
  padding-right:3 rem;
  margin:auto;
}
p.line{
  display:inline-block
}

.console-underscore {
  display:inline-block;
}

.sectionthree {
  display: flex;
  top:0;
  height: 110vh;
  font-family: 'Playfair', serif;
  font-size: larger;
  color:rgb(25, 24, 22);
}

.sectionthree::-webkit-scrollbar {
    display: none;
}

.arrow {
  min-width: 40vh;
}

.bookcontainer {
  max-width: 100vw;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
  font-size: 1.7rem;


 }

 .stf__parent{
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;

 }

.pages{
  display: flex;
  margin-bottom:30%;
  margin-top:7%;
}

.leftContainer {
  position: inherit;
  padding: 5%;
  background-repeat:no-repeat;
  background-size: contain;
  background-position-x: right;

  vertical-align: middle;
  left: 0;
}
.rightContainer {
  position: inherit;
  background-repeat:no-repeat;
  height: 80vh;
  justify-content: center;
  padding: 2%;


}

#first.leftContainer {
  background-color: #A69C9C;
  text-align: center;
  
}
#first.rightContainer {
  background-size: cover;
  background-image: url("images/cover3.jpg");
}
#second.leftContainer {
  background-image: url("images/norw2.jpg");
}
#second.rightContainer {
  background-color: #A69C9C;
}
#third.leftContainer {
  background-color: #A69C9C;
}
#third.rightContainer {
  background-image: url("images/antibescro.jpg");
  background-size: contain;
}
#forth.leftContainer {
  background-image: url("images/noaa.jpg");
  background-size: cover;
}
#forth.rightContainer {
  background-color: #A69C9C;
}
#fifth {
  background-color: #474c5d;
  color:rgb(243, 207, 130);
  text-align: center;
}

.sectionend {
  display: flex;
  height: 90vh;
}
.contact {
  height: 10vh;
  color: azure;

  font-size: 2ch;
  text-align: center;
}

a {
  font-family: helvetica;
  text-decoration: none;
  text-transform: uppercase;
  color: azure;
  padding: 1em 1.5em;
  background-color: rgb(184, 24, 253);
  target-name:new;
target-new:tab;

}
a:hover {
  background-color: #555;
}

a:active {
  background-color: black;
}


a:hover {
  cursor: pointer;
}

.rocketimg{
  flex:1;
  float: left;
  background-image: url("images/fish2.gif");
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;

}

.thanks{
  flex: 1;
  color: azure;
  width: 50%;
  float: left;
  font-size: 5vh;
  text-align: center;
  margin-top: auto;
  text-align: left;
   margin-bottom: auto;
   font-family: 'Khula';
}

.animate-me {
  animation: bounceIn 3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}